/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  html {
    font-size: 86%;
  }

  .content {
    .intro {
      height: 770px;

      .intro__links-menu ul li {
        width: 155px;
        padding-left: 8px;
      }
    }

    .moduletable_income .income .income_wrapper--text .income_wrapper__header {
      font-weight: 300;
      padding-bottom: 15px;
      margin-bottom: 30px;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  html {
    font-size: 62%;
  }

  .header-main {
    .main-logo {
      width: 33%;
    }

    .header-text {
      height: 177px;
    }

    .header-contacts {
      padding-top: 10px;

      .header-contacts__phone {
        a {}
      }

      .header-contacts__button {
        width: 140px;
        height: 46px;
      }
    }

    .header-menu .moduletable_menu {
      padding: 0 30px;
    }
  }

  .content .intro {
    padding-top: 100px;
    height: 554px;

    .intro__text {}
  }

  .intro--background {
    background-size: auto 554px;
  }

  .content .intro .intro__links-menu ul li {
    width: 16%;
    height: 271px;
    padding-left: 6px;
    margin-top: 0;

    .img-wrapper {
      height: 163px;
      position: relative;
      margin-bottom: 30px;
    }

    a {}
  }

  .moduletable_advantages {
    margin-bottom: 80px;
  }

  .moduletable_income .income .income_wrapper {
    height: 254px;
  }


  .content .moduletable_income .income .income_wrapper--text .income_wrapper__header {
    font-weight: 300;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }


  footer .footer__contacts,
  footer .google_map {
    height: 340px;
  }

  footer .footer__contacts .footer__contacts--title {
    font-size: 2.56rem;
    line-height: 30px;
  }

  footer .footer__contacts p {
    color: #f3f6f7;
    font-size: 1.4rem;
    line-height: 20px;
  }

  footer .copyright {
    font-size: 1.7rem;
    padding: 4% 0 0 5%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  html {
    font-size: 50%;
  }

  #scrollUp {
    bottom: 10px;
    right: 10px;
  }

  .intro__links-menu {
    margin-top: 8%;
    .links-menu__image {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .header-main .main-logo {
    width: 38%;
    padding: 10px;
  }

  .header-main .header-text {
    height: 80px;
    padding-top: 33px;
    padding-right: 15px;
    float: right;
  }

  .header-main .header-contacts {
    padding: 10px 15px 15px 0;

    p, button {
      display: inline-block;
      margin-left: 15px;
    }
  }

  .header-main .header-menu ul li a {
    height: 35px;
    line-height: 35px;
    img {
      width: 20px;
    }
  }

  .header-main .header-menu ul, .header-main .header-menu .moduletable_menu, .header-main .header-menu {
    height: 35px;
  }

  .content .intro .intro__text {
    text-align: center;
    width: 100%;
  }

  .content .intro .intro__links-menu ul {
    display: flex;
    justify-content: space-between;
    li {
      height: 251px;
      padding-left: 6px;
      margin-top: 0;

      a {
        font-size: 1.18rem;
      }

      .img-wrapper {
        height: 149px;
        position: relative;
        margin-bottom: 30px;
      }
    }
  }

  .content .moduletable_advantages .advantages ul li {
    float: left;
    width: 23%;
  }

  .content .moduletable_advantages .advantages ul li a {
    text-align: center;
  }

  .content .moduletable_income .income .income_wrapper--text .income_wrapper__header {
    font-weight: 300;
    padding-bottom: 15px;
    margin-bottom: 10px;
    font-size: 3.5rem;
  }

  .moduletable_income .income .income_wrapper--text {
    padding: 20px;
    p {
      font-size: 2rem;
    }
    .income_wrapper__header {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }

  .content .moduletable_question h3 {
    margin-bottom: 2%;
  }

  .moduletable_question .qlform_question form {
    margin: 0 auto;
    width: 100%;
  }

  .form_question {
    form {
      margin: 0 auto;
      width: 970px;
    }
  }

  footer .footer__contacts {
    padding: 25px 0 0 25px;
    background-color: #545553;
    font-family: Oswald;
    font-weight: 300;
  }

  footer .copyright {
    padding: 15px 0 0 25px;
  }

  footer .footer__contacts,
  footer .google_map {
    height: 263px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

  .popup-form {
    justify-content: flex-start;
    .moduletable_popup-form {
      max-width: 100%;
      max-height: 100%;
      margin-top: 0;
      width: 100%;
      height: 100%;
    }
    h3 {
      padding-left: 4%;
    }
    fieldset {
      border: none;
      margin: 0;
      padding: 0;
    }
    input, textarea {
      width: 94%;
      margin-left: 2%;
    }
    button {
      width: 94%;
      margin-left: 2%;
    }
  }

  .content .intro .intro__links-menu ul {
    li {
      a {
        font-size: 2.3rem;
      }
    }
  }

  span.popup_close_button {
    right: 4% !important;
  }

  .header-main .header-menu .moduletable_menu {
    padding: 0 5px;
  }

  #scrollUp {
    bottom: 10px;
    right: 10px;
    padding: 0;
  }

  .intro--background {
    background-size: cover;
  }
  .content .intro {
    min-height: min-content;
    height: auto;
    .intro__links-menu ul {
      width: 100%;
      display: inline-block;
      li {
        float: none;
        width: 100%;
        height: auto;
        @include clearfix;
        a {
          display: flex;
          align-items: center;
          justify-content: space-around;

          p {
            flex-basis: 30%;
          }
          .clear {
            flex-basis: 0%;
          }
          .img-wrapper {
            flex-basis: 30%;
            height: auto;
            position: relative;
            margin-bottom: 0;
            img {
              position: relative;
              float: right;
            }
          }
        }
      }
    }
  }
  .moduletable_income .income .income_wrapper--text p {
    font-size: 1.4rem;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .content .intro .intro__links-menu ul li a {
    color: #ffffff;
    font-family: Oswald;
    font-size: 6px;
    font-weight: 700;
  }
}